<template>
  <!-- 代理企业详情 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <a-button
          @click="
            $jump({
              path: '/agentEnterpriseBindArea',
              query: {
                id: info.id,
                name: info.name
              }
            })
          "
        >
          关联地区
        </a-button>
      </template>
    </a-page-header>

    <div class="main-content">
      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">企业信息</div>
        </div>

        <div class="content-sub-title">概括</div>

        <div class="ul">
          <div class="li">
            <span>企业名称：</span>
            <span>{{ info.name }}</span>
          </div>
          <div class="li">
            <span>企业法人：</span>
            <span>{{ info.person }}</span>
          </div>
          <div class="li">
            <span>负责人姓名：</span>
            <span>{{ info.head }}</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>负责人联系方式：</span>
            <span>{{ info.mobile }}</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">
          <span>代理地区</span>
          <a-button
            type="link"
            @click="
              $jump({
                path: '/agentEnterpriseBindArea',
                query: {
                  id: info.id,
                  name: info.name
                }
              })
            "
          >
            关联地区
          </a-button>
        </div>

        <a-table
          :columns="columns"
          :data-source="info.area"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 800 }"
        >
          <template slot="status" slot-scope="record">
            <a-badge
              status="success"
              :text="statuText[record.status]"
              v-if="record.status"
            />
            <a-badge status="default" :text="statuText[record.status]" v-else />
          </template>
          <template slot="action" slot-scope="record">
            <a-button type="link">
              <span v-if="record.status">禁用</span>
              <span v-else>启用</span>
            </a-button>
            <a-button type="link">
              解除绑定
            </a-button>
          </template>
        </a-table>
      </div>

      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">账户信息</div>
        </div>

        <div class="content-sub-title">
          <span>微信账户</span>
          <a-button type="link" @click="onBindWX">绑定/解绑微信</a-button>
        </div>

        <div class="ul">
          <div class="li">
            <span>微信昵称：</span>
            <span v-if="info.wechat">{{ info.wechat.nickname }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>微信号持有人：</span>
            <span v-if="info.wechat">{{ info.wechat.name }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>持有人联系方式：</span>
            <span v-if="info.wechat">{{ info.wechat.mobile }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <div class="content-sub-title content-sub-title-2">
          <span>银行卡信息</span>
          <a-button type="link">填写/修改银行卡</a-button>
        </div>

        <div class="ul">
          <div class="li">
            <span>银行卡类型：</span>
            <span v-if="info.banknumber">{{
              brancktype[info.brancktype]
            }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>开户银行：</span>
            <span v-if="info.bankname">{{ info.bankname }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>银行卡号：</span>
            <span v-if="info.banknumber">{{ info.banknumber }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>持卡人：</span>
            <span v-if="info.bankUser">{{ info.bankUser }}</span>
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="modalVisible"
      title="账户信息 - 绑定/解绑微信"
      :footer="false"
    >
      <a-form-model
        ref="bindRuleForm"
        :model="bindForm"
        :rules="bindRules"
        v-if="bindForm.openid"
      >
        <a-form-model-item label="微信昵称">
          <a-input v-model="bindForm.nickname" disabled />
        </a-form-model-item>
        <a-form-model-item ref="name" label="微信号持有人" prop="name">
          <a-input
            v-model="bindForm.name"
            placeholder="请输入持有人姓名"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="mobile" label="持有人联系方式" prop="mobile">
          <a-input
            v-model="bindForm.mobile"
            placeholder="请输入11位手机号"
            @blur="
              () => {
                $refs.mobile.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button class="cancel-btn" key="refuse" @click="handleCancel">
            取 消
          </a-button>
          <a-button
            key="agree"
            type="primary"
            :loading="bindLoading"
            @click="handleSubmit"
          >
            确 定
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <iframe
        id="iframe"
        :src="wechatLink"
        width="300px"
        height="390px"
        frameborder="0"
        name="iframea"
        v-else
      ></iframe>
    </a-modal>
  </div>
</template>

<script>
const checkMobile = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入11位手机号'))
  } else {
    if (value.length < 11) {
      callback(new Error('请输入正确的11位号码'))
    } else {
      callback()
    }
  }
}
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      info: null,
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '代理地区',
          dataIndex: 'province',
          width: 200,
          customRender: (text, record) => {
            return record.province + record.city + record.district
          }
        },
        {
          title: '代理时间',
          dataIndex: 'createAt',
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      statuText: {
        1: '启用',
        0: '禁用'
      },
      brancktype: {
        1: '企业公账',
        2: '企业私账'
      },
      modalVisible: false,
      wechatLink: '',
      bindForm: {
        // 绑定微信
        proxyId: '',
        openid: '',
        nickname: '',
        name: '',
        mobile: ''
      },
      bindRules: {
        // 绑定地区
        name: [
          { required: true, message: '请输入持有人姓名', trigger: 'blur' }
        ],
        mobile: [
          {
            required: true,
            validator: checkMobile,
            message: '请输入11位手机号',
            trigger: 'blur'
          }
        ]
      },
      bindLoading: false
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    if (query.code) {
      // 绑定微信
      const data = {
        id: query.proxyId,
        code: query.code
      }
      this.$axios.getAgentEnterpriseBindWX(data).then((res) => {
        const r = res.data.data
        this.bindForm.proxyId = query.proxyId
        this.bindForm.openid = r.openid
        this.bindForm.nickname = r.nickname
        console.log(this.bindForm)
        this.modalVisible = true
      })
      this.getAgentEnterpriseInfo(query.proxyId)
    } else {
      this.getAgentEnterpriseInfo(query.id)
    }
  },
  methods: {
    getAgentEnterpriseInfo(id) {
      // 获取代理企业详情
      const data = {
        id: id
      }
      this.$axios.getAgentEnterpriseInfo(data).then((res) => {
        this.info = res.data.data
      })
    },
    onBindWX() {
      // 弹出绑定微信组件
      const data = {
        name: this.info.name,
        mobile: this.info.mobile,
        proxyId: this.info.id
      }
      this.$axios.getAgentEnterpriseBindWXLink(data).then((res) => {
        this.wechatLink = res.data.data.url
        this.modalVisible = true
      })
    },
    handleCancel() {
      // 取消绑定微信账号（钱包）
      this.modalLoading = false
      this.modalVisible = false
      this.$refs.bindRuleForm.resetFields()
    },
    handleSubmit() {
      // 绑定微信账号（钱包）
      this.$refs.bindRuleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true
          this.$axios.agentEnterpriseBindWX(this.bindForm).then(() => {
            this.$message.success('操作成功')
            this.handleCancel()
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        }
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.content-sub-title {
  margin: 24px 0 16px 0;
}

.content-sub-title-2 {
  margin: 60px 0 16px 0;
}

#iframe {
  margin-left: 86px;
}

.cancel-btn {
  margin-right: 8px;
}
</style>
